.Interview em {
    /*font-weight: bold;
    font-style: normal;
    color: #999999;*/
}

.Interview {
    max-width: 900px;
    margin: 1.5em auto;
}

.Project-status + .Interview {
    margin-top: 2.5em;
}

@media screen and (min-width: 50em) {

    .Interview > * {
        padding: 0 10%;
    }
    
    .Interview > .Embed,
    .Interview > .Image {
        padding: 0;
    }

}