@viewport {
    width: device-width;
    zoom: 1;
}

* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

html,
body {
    min-height: 100%;
}

body {
    background-color: #D9D9D4;
}

body,
input {
    font-size: 16px;
    font-family: "Atlas Grotesk Web", sans-serif;
    line-height: 1.5;
    text-rendering: auto;
    color: #191919;
}

input {
    border: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

a,
a:link {
    color: #2224B4;
    text-decoration: none;
}

a:visited {
    color: #660066;
}

a:focus {
    color: orange;
}

a:hover,
a.is-current {
    color: #B30000;
}

figure {
    display: block;
    margin: 0;
    padding: 0;
}

img {
    vertical-align: top;
    max-width: 100%;
    height: auto;
}

p,
ul,
ol {
    margin: 24px 0;
}

/**
 * Headings
 */
h1 {
    font-size: 24px;
    margin: 16px 0;
}

h2 {
    font-size: 21px;
    margin: 16px 0;
}

h3 {
    font-size: 18px;
    margin: 16px 0;
}

h4, h5, h6 {
    font-weight: bold;
    font-size: inherit;
    line-height: inherit;
}

em {
    font-weight: bold;
    font-style: normal;
}

cite {
    font-weight: bold;
    font-style: normal;
}

/**
 * Blockquote
 */
blockquote {
    padding: 0 24px;
    margin: 24px 0;
    font-size: 16px;
    line-height: 24px;
}

blockquote p:first-child {
    margin-top: 0;
}

blockquote cite {
    display: block;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    margin-top: 12px;
    text-align: right;
}

blockquote p {
    margin-bottom: 0px;
    text-indent: -8px;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote p:first-child {
    margin-top: 0;
}

blockquote p:before {
    content: "“";
}

blockquote p:after {
    content: "”";
}

blockquote p:last-child {
    margin: 0;
}

blockquote p:last-child:before {
    content: none;
}

blockquote p:last-child:after {
    content: none;
}

small {
    font-size: 12px;
}

small:only-child { /* caption */
    text-align: center;
    display: block;
}

strong {
    font-weight: bold;
}

@media screen and (min-width: 50em) {

    /**
     * Headings
     */
    h1 {
        font-size: 32px;
        line-height: 48px;
        margin: 24px 0;
    }
    
    h2 {
        font-size: 24px;
        line-height: 36px;
        margin: 24px 0;
    }
    
    h3 {
        font-size: 21px;
        line-height: 23px;
        margin: 24px 0;
    }

    /**
     * Blockquote
     */
    blockquote {
        font-size: 18px;
        line-height: 26px;
    }

    blockquote cite {
        font-size: 14px;
        line-height: 21px;
    }

    small {
        font-size: 14px;
    }

}

@media screen and (min-width: 70em) {

    blockquote {
        padding: 0 42px;
        font-size: 21px;
        line-height: 31px;
    }

}