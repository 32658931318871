.Header {
    padding: 1.5em 1em;
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
}

.Logo {
    width: 66px;
    height: 66px;
    margin: 0 auto;
}

.Nav,
.Tagline {
    padding: 0;
    margin: 1em 0;
}

.Tagline {
    display: none;
}

.Tagline-text {
    display: block;
    max-width: 27em;
    margin: 0 auto;
    font-size: 14px;
}

.Nav-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.Nav-item {
    display: inline-block;
    margin: 0 .25em;
}

@media screen and (min-width: 70em) {

    /**
     * Go flexbox on the header
     */
    .Logo {
        width: 118px;
        height: 118px;
        order: 2;
    }

    .Header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .Nav {
        order: 1;
        flex: 1;
    }
    
    .Tagline {
        display: block;
        order: 3;
        flex: 1;
    }

}