/**
 * Projects
 */
.Projects-heading {
    text-align: center;
    margin-top: 0;
}

.Projects-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.Projects-project {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 1.5em 0;
}

.Projects-url {
    display: block;
    outline: 0;
}

.Projects-image img {
    outline: 5px solid #2224B4;
    outline-offset: -20px;
}

.Projects-url:visited .Projects-image img {
    outline-color: #660066;
}

.Projects-url:hover .Projects-image img {
    outline-color: #B30000;
}

.Projects-image--portrait {
    position: relative;
    display: block;
}

.Projects-image--portrait img {
    width: auto;
    max-height: 100%;
}

.Projects-image--portrait:after {
    padding-top: 100%; /* 1:1 ratio */
    display: block;
    content: '';
}

.Projects-image--portrait img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.Projects-header {
    text-align: center;
}

.Projects-title {
    font-size: 1.2em;
    margin: .8em 0 .2em 0;
}

.Projects-subtitle {
    font-size: .9em;
    font-weight: bold;
    margin: 0 0 0em 0;
}

.Projects-category {
    display: block;
    color: #222;
    font-size: .8em;
    text-transform: lowercase;
    font-weight: normal;
}

@media screen and (min-width: 40em) {
    .Projects-list {
        text-align: center;
        padding: .75em;
    }

    .Projects-project {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        padding: .75em;
    }
}

@media screen and (min-width: 70em) {

    .Projects-project {
        /*padding: 24px;*/
        width: 33.3334%;
    }

}