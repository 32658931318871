.Press {
    text-align: center;
    margin-bottom: 2.5em;
}

.Press-header {
    text-align: center;
    margin-bottom: 1.5em;
}

.Press-title {
    margin: 0;
}

.Press-projects {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Press-project + .Press-project {
    margin-top: 1.25em;
}

.Press-subtitle {
    font-weight: bold;
    margin-top: 0;
}

.Press-text {
    margin: 0 auto 1.5em auto;
    max-width: 671px;
}

.Press-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Press-item {
    padding: 24px 0;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.Press-image {
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 400px;
}

.Press-image > img {
    max-height: 100%;
    width: auto;
}

.Press-caption {
    margin-top: 12px;
    display: block;
}

.Press-image--portrait:after {
    padding-top: 100%; /* 1:1 ratio */
    display: block;
    content: '';
}

.Press-image--portrait > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.Press-file {
    background-image: url(../images/file.png);
    background-repeat: no-repeat;
    display: block;
    background-size: contain;
    background-position: center;
    min-height: 230px;
    text-transform: uppercase;
    font-size: 1.25em;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 30em) {

    .Press-items {
        text-align: left;
        margin-left: -16px;
        margin-right: -16px;
    }

    .Press-item {
        width: 50%;
        padding: 24px;
    }

}

@media screen and (min-width: 50em) {

    .Press-item {
        width: 33.3333%;
    }

}

@media screen and (min-width: 70em) {

    .Press-item {
        width: 25%;
    }

}