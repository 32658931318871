/**
 * Site footer
 */
.Footer {
    display: block;
    padding: 1.5em 1em;
    background-color: #D9D9D4;
}

.Footer-sections {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
}

.Footer-section + .Footer-section {
    margin-top: 3em;
}

.Footer-heading {
    font-size: .875em;
    margin: 0;
    text-align: center;
}

.Footer-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.Footer-body,
.Footer-items {
    font-size: .875em;
}

.Footer-item {
    margin: 24px 0;
}

.Footer-item > p {
    margin: 0;
}

.Footer-body > :first-child,
.Footer-item:first-child {
    margin: .75em 0 0 0;
}

.Footer-body > :last-child {
    margin-bottom: 0;
}

.Footer-section--contact,
.Footer-section--exhibitions,
.Footer-section--retailers {
    text-align: center;
}

.Subscribe {
    max-width: 480px;
    margin: 0 auto;
}

/**
 * Newsletter
 */
.Newsletter {
    font-size: .875em;
}

.Newsletter-response {
    display: block;
    margin-bottom: 1.5em;
}

.Newsletter-response--error {
    color: #B30000;
}

.Newsletter-fields {
    margin: 0;
    padding: 0;
    list-style: none;
}

.Newsletter-field {
    position: relative;
}

.Newsletter-field {
    margin: 1.5em 0;
}

.Newsletter-label {
    font-weight: bold;
    display: block;
}

.Newsletter-label--error {
    color: #B30000;
    animation-name: shake;
    animation-duration: 0.85s;
}

.Newsletter-label--error + .Newsletter-input {
    border-color: #B30000;
}

.Newsletter-input {
    width: 100%;
    margin-bottom: -1px;
    border-bottom: 1px solid #999;
}

.Newsletter-submit {
    border: 0;
    padding: 0;
    font-weight: bold;
    text-align: left;
    color: #2224B4;
    font-size: inherit;
}

.Newsletter-input,
.Newsletter-submit {
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    font-size: inherit;
}

@keyframes shake {
    0%, 100% { -webkit-transform: translateX(0); transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { -webkit-transform: translateX(-2px); transform: translateX(-2px); }
    20%, 40%, 60%, 80% { -webkit-transform: translateX(2px); transform: translateX(2px); }
}

@media screen and (min-width: 50em) {

    .Footer-sections {
        text-align: center;
        max-width: 680px;
    }


    .Footer-section {
        padding: 1.5em 1.5em;
        text-align: left;
    }

    .Footer-section + .Footer-section {
        margin-top: 0;
    }

    .Footer-section--subscribe,
    .Footer-section--contact,
    .Footer-section--exhibitions,
    .Footer-section--retailers {
        display: inline-block;
        vertical-align: top;
    }


    .Footer-section--contact,
    .Footer-section--exhibitions,
    .Footer-section--retailers {
        width: 33.3334%;
    }

    .Footer-section--subscribe {
        width: 100%;
    }

    .Footer-heading {
        text-align: left;
    }

}

@media screen and (min-width: 75em) {

    .Footer-sections {
        max-width: 1200px;
    }

    .Footer-section--contact,
    .Footer-section--exhibitions,
    .Footer-section--retailers {
        width: 19%;
    }

    .Footer-section--subscribe {
        width: 43%;
    }

    .Footer-heading {
        text-align: left;
    }

}