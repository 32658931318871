.NewsArticle {
    max-width: 720px;
    margin: 0 auto;
}

.NewsArticle + .NewsArticle {
    margin-top: 6em;
}

.NewsArticle:first-child {
    margin-top: 0;
}

.NewsArticle-header {
    text-align: center;
    margin-bottom: 1.5em;
}

.NewsArticle-title {
    margin: 0;
}