/**
 * Post
 */
.Post {
    max-width: 600px;
    margin: 0 auto;
}

.Post-header {
    text-align: center;
    margin-bottom: 1.5em;
}

.Post-title {
    margin: 0;
}