/**
 * Project
 */
.Project-header {
    text-align: center;
    margin-bottom: 2.5em;
}

.Project-footer {
    margin-top: 2.5em;
    text-align: center;
}

.Project-title {
    margin: 0 auto;
    max-width: 20em;
}

.Project-subtitle {
    font-weight: bold;
    margin: 0;
}

.Project-status {
    padding: 1.5em;
    font-weight: bold;
    background-color: #ffffff;
    margin: 0 auto;
}

@media screen and (min-width: 40em) {

    .Project-status {
        width: 50%;
    }

}