/**
 * Embed
 */
.Embed {
    position: relative;
    margin: 0;
    width: 100%;
    overflow: hidden;
    background-color: #D9D9D4;
}

.html .Embed-wrapper {
	margin: 24px 0;
}

.Embed span {
    padding: 1em;
    display: block;
    text-align: center;
}

.Embed iframe {
    width: 100%;
}

.Embed--vimeo iframe,
.Embed--youtube iframe {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Embed--mixcloud {
    max-height: 180px;
}