.Podcasts {}

.Podcasts-heading {
    margin: 0;
    text-align: center;
}

.Podcasts-heading + .Podcasts-text {
    margin: 0 auto 2.5em auto;
    max-width: 720px;
    text-align: center;
}

.Podcasts-podcast {
    margin: 3em auto;
    max-width: 720px;
}

.Podcasts-podcast--white {
    background-color: white;
    padding: 1.5em 0 0 0;   
}

.Podcasts-podcast--white .Podcasts-body {
    padding: 1.5em;
}

.Podcasts-podcast--white .Podcasts-body > .Podcasts-text:first-child {
    margin-top: .5em;
}

.Podcasts-podcast .Embed {
    background-color: inherit;
    width: 100%;
}

.Podcasts-header {
    text-align: center;
    margin-bottom: 1.5em;
}

.Podcasts-title {
    margin: 0;
}

.Podcasts-text--teaser {
    font-weight: bold;
}

.Podcasts-text {
    margin-top: 1.5em;
}

.Podcasts-more {
    display: block;
    font-weight: bold;
    margin-top: 1.5em;
    text-align: center;
}

@media screen and (min-width: 37.5em) {

    .product-text {
        font-weight: bold;
    }

    .product[data-size='tall'] img {
        max-width: 100%;
    }

    .product[data-size='tall'] .product-content {
        max-width: 66.6667%; /* aspect ratio */
    }


}