/**
 * Page
 */
.Page {
    margin: 0 auto;
    max-width: 670px;
    background-color: transparent;
}

.Page--default {
	margin-bottom: 2em;
}

.Page-heading {
    margin-top: 0;
    text-align: center;
}

.Page-section {
    margin: 2em;
}

.Page-section--contact,
.Page-section--imprint,
.Page-section--registration {
    text-align: center;
}

.Page-subheading {
    padding: 1em 0 0 0;
    margin: 0;
}