/**
 * Posts
 */
.Posts-post {
    margin: 2.5em 0;
}

.Posts-post--center {
    padding: 1.5em;
    font-weight: bold;
    background-color: #ffffff;
}

.Posts-body > :last-child {
    margin-bottom: 0;
}

.Posts-body img {
    display: block;
    margin: 0 auto;
}

.Posts-readmore {
    display: block;
    text-align: center;
    margin-top: 1.5em;
}

.Posts-rodate {
    display: none;
}

.Posts-header {
    text-align: center;
    margin-bottom: 1.5em;
}

.Posts-title {
    margin: 0;
}

.Posts-author {
    display: inline-block;
}

.Posts-author::first-letter {
    text-transform: lowercase;
}

@media screen and (min-width: 50em) {

    .Project-status + .Posts {
        padding-top: 2.5em;
    }

    .Project-status + .Posts > .Posts-post {
        margin-top: 0;
    }

    .Posts {
        position: relative;
     }

    .Posts:after {
        content: ' ';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 50%;
        height: 100%;
        display: block;
        border-left: 1px dotted rgba(0,0,0,.2);
    }

    .Posts-post--right {
        text-align: right;
    }

    .Posts-post--center {
        position: relative;
        z-index: 3;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .Posts-body {
        width: 50%;
        text-align: left;
    }

    .Posts-post--left .Posts-body {
        padding-right: 3.25em; /* padding + line-height/2 */
    }

    .Posts-post--right .Posts-body {
        padding-left: 3.25em; /* padding + line-height/2 */
    }

    .Posts-post--center .Posts-body {
        width: 100%;
    }

    .Posts-body,
    .Posts-rodate {
        display: inline-block;
        vertical-align: middle;
    }

    .Posts-rodate {
        position: relative;
        z-index: 3;
        width: 10em;
        text-align: center;
        font-weight: bold;
        transform-origin: center center;
    }

    .Posts-rodate .Posts-date {
        font-size: .85em;
        background-color: #E3E3DE;
        padding: 0 .25em;
    }

    .Posts-post--left .Posts-rodate {
        transform: rotate(90deg);
        margin-left: -5em;
        margin-left: -4.9375em; /* cover 1px difference to align the dates */
    }

    .Posts-post--right .Posts-rodate {
        transform: rotate(-90deg);
        margin-right: -5em;
    }

    .Posts-header .Posts-date {
        display: none;
    }

    .Posts-post--center .Posts-rodate {
        display: none;
    }

}