/**
 * Markdown output
 */
.html > :first-child {
    margin-top: 0;
}

.html > :last-child {
    margin-bottom: 0;
}

.html--center {
    text-align: center;
}

.Attribution {
    display: block;
    margin-top: -1em;
    font-size: 11px;
}

.no-color {
    color: #191919;
}

/**
 * Small text
 */
.small {
    font-size: 12px;
}

@media screen and (min-width: 50em) {

    .Attribution {
        font-size: 13px;
    }

    .small {
        font-size: 14px;
    }

}