.Podcast {
    margin: 0 auto 3em auto;
    max-width: 1000px;
    text-align: center;
}

.Podcast--white {
    background-color: white;
    padding: 1.5em 0 0 0;   
}

.Podcast--white .Podcast-body {
    padding: 1.5em;
}

.Podcast--white .Podcast-body > .Podcast-text:first-child {
    margin-top: .5em;
}

.Podcast .Embed {
    
}

.Podcast-header + .Embed-wrapper {
    background-color: inherit;
    margin: 0 auto;
    max-width: 720px;
}

.Podcast-header {
    text-align: center;
    margin-bottom: 1.5em;
}

.Podcast-title {
    margin: 0;
}

.Podcast-text--teaser {
    font-weight: bold;
}

.Podcast-text {
    margin-top: 1.5em;
}

.Podcast-text > p {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.Podcast-more {
    display: block;
    font-weight: bold;
    margin-top: 1.5em;
    text-align: center;
}