.html figure {
    margin: 1.75em auto;
    text-align: center;
}

.html figure[data-size="portrait"] div {
    position: relative;
    width: 90%;
    margin: 0 auto;
    max-width: 600px;
}

/*.html figure[data-size="portrait"] img {
    max-height: 100%;
    width: auto;
    border: 1px solid red;
}*/

/*.html figure[data-size="portrait"] div:after {
    padding-top: 100%;
    display: block;
    content: '';
}*/

/*.html figure[data-size="portrait"] img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}*/

.html figure > figcaption {
    display: block;
    font-size: 11px;
    margin: 12px auto;
    width: 80%;
}

.html figure > figcaption > :first-child {
    margin-top: 0;
}

.html figure > figcaption > :last-child {
    margin-bottom: 0;
}


@media screen and (min-width: 50em) {

    .html figure > figcaption {
        font-size: 13px;
    }

}
