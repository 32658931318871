.Paginate {
    margin: 3em auto 1.5em auto;
}

.Paginate-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Paginate-item {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.Paginate-item--left {
    text-align: center;
}

.Paginate-item--left .Paginate-label:before {
    display: inline-block;
    margin-right: .25em;
    content: '«';
}

.Paginate-item--right .Paginate-label:after {
    display: inline-block;
    margin-left: .25em;
    content: '»';
}

.Paginate-item--right {
    text-align: center;
}

.Paginate-item--index {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: .75em;
}

.Paginate-label {
    display: block;
}

.Paginate-title {
    display: none;
}

@media screen and (min-width: 50em) {

    .Paginate-title {
        display: block;
        font-size: .8125em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

}