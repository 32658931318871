/*
    This font software is the property of Commercial Type.

    You may not modify the font software, use it on another website, or install it on a computer.

    License information is available at http://commercialtype.com/eula
    For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

    Copyright (C) 2013 Schwartzco Inc.
    License: 1308-DJDXWP     
*/
@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../fonts/AtlasGrotesk-Regular-Web.eot');
  src: url('../fonts/AtlasGrotesk-Regular-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AtlasGrotesk-Regular-Web.woff') format('woff'),
       url('../fonts/AtlasGrotesk-Regular-Web.ttf') format('truetype'),
       url('../fonts/AtlasGrotesk-Regular-Web.svg#Atlas Grotesk Web') format('svg');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../fonts/AtlasGrotesk-Bold-Web.eot');
  src: url('../fonts/AtlasGrotesk-Bold-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AtlasGrotesk-Bold-Web.woff') format('woff'),
       url('../fonts/AtlasGrotesk-Bold-Web.ttf') format('truetype'),
       url('../fonts/AtlasGrotesk-Bold-Web.svg#Atlas Grotesk Web') format('svg');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Typewriter Web';
  src: url('../fonts/AtlasTypewriter-Regular-Web.eot');
  src: url('../fonts/AtlasTypewriter-Regular-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AtlasTypewriter-Regular-Web.woff') format('woff'),
       url('../fonts/AtlasTypewriter-Regular-Web.ttf') format('truetype'),
       url('../fonts/AtlasTypewriter-Regular-Web.svg#Atlas Typewriter Web') format('svg');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}
